$title-background: #252323;

@mixin set-border-width($left-bd-width, $top-bd-width, $right-bd-width, $bottom-bd-width) {
  @if $left-bd-width != null {
    border-left-width: $left-bd-width;
  }
  @if $top-bd-width!= null {
    border-top-width: $top-bd-width;
  }
  @if $right-bd-width != null {
    border-right-width: $right-bd-width;
  }
  @if $bottom-bd-width != null {
    border-bottom-width: $bottom-bd-width;
  }
}

body {
  font: 1rem/1.5 -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    'Microsoft Yahei';
  letter-spacing: 0.03em;
}

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //background: #f5f5f5;
}

.doc {
  &.left-col {
    height: calc(100vh - 80px);
    //background: #fff;
  }

  &.content-col {
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
  }

  &.code {
    margin: 0 0.15em;
    padding: 0 0.15em;
    font-size: 0.8em;
    background: #f2f4f5;
    border: 1px solid #f0f0f0;
    border-radius: 0.25em;
    font-weight: 500;
    color: #e41f4d;

    &.normal {
      font-size: 1em;
      font-weight: 400;
    }
  }

  &.home-header {
    margin: 0 auto;

    &.transparent {
      > .navbar {
        background: transparent;
      }
    }

    &:not(.transparent) {
      > .navbar {
        &:not(.affix-fixed) {
          background: radial-gradient(
            circle at left top,
            rgba(99, 65, 249, 0.9) 15%,
            rgba(10, 95, 248, 0.9) 50%,
            rgba(33, 180, 212, 0.9) 85%
          );
          //background: radial-gradient(circle at left top,
          //        rgba(129, 38, 96, 0.9) 15%,
          //        rgba(70, 28, 100, 0.9) 50%,
          //        rgba(67, 13, 76, 0.9) 85%);
          //background-size: cover;
        }
      }
    }

    > .navbar {
      padding: 0 5%;
      border-radius: 0;
      transition: line-height 0.3s;

      &.affix-fixed {
        line-height: 4rem;
        background: #292929;
        padding: 0 5%;
      }

      > .title {
        text-shadow: 1px 1px 2px #333, 0 0 25px #fff, 0 0 5px #0ca0ff;
      }
    }
  }

  &.normal-panel {
    //used
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5rem 0;
    width: 100%;
    flex-direction: column;
  }

  &.home-footer {
    height: 60px;
    background: #333;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    padding: 0.5rem 5%;
    color: #fff;

    .footer-panel {
      min-width: 15rem;
      //box-shadow: -1px 0 0 0 rgba(255, 255, 255, .2);
      padding-left: 1rem;

      .footer-title {
        margin-bottom: 0.25rem;
        color: #49b847;
      }
    }
  }

  &.home-link {
    //used
    list-style: none;
    color: rgba(255, 255, 255, 0.6);

    > li {
      padding: 0.5rem 0;
      font-size: 0.8rem;

      &:focus,
      &:hover,
      &:active {
        color: #fff;
      }
    }
  }

  p {
    //margin-top: 0.25rem;
    //margin-bottom: 1rem;
  }

  &.home {
    //height: calc(100vh - 60px);
    height: 100vh;
    width: 100%;
    background: radial-gradient(
      circle at left top,
      rgba(98, 72, 213, 0.9) 15%,
      rgba(12, 63, 153, 0.9) 50%,
      rgba(38, 16, 135, 0.9) 85%
    );
    //background: radial-gradient(circle at left top,
    //        rgba(129, 38, 96, 0.9) 15%,
    //        rgba(70, 28, 100, 0.9) 50%,
    //        rgba(67, 13, 76, 0.9) 85%);
    background-size: cover;

    //&.dark {
    //  background: radial-gradient(circle at left top, rgba(83, 83, 84, .9) 15%, rgba(70, 70, 70, .9) 50%, rgba(32, 33, 33, .9) 85%);
    //}
  }

  &.inverted-row {
    padding: 1rem;
    background: #423e3e;

    > .button {
      margin: 0.5rem 0.5rem 0.5rem 0;
    }
  }

  &.markdown {
    margin-bottom: 2rem;

    table {
      border: 1px solid #ccc !important;
      overflow: auto;
      display: block; //to make overflow take effects todo: check whether the scrool bar can display in mobile phone
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      direction: ltr;
      empty-cells: show;
      table-layout: fixed;
      margin-bottom: 1rem;
      //font-size: .8em;
      border-left-width: 0;
      border-right-width: 0;
      font-size: 0.9em;

      th {
        white-space: nowrap;
        text-align: left;
        padding: 0.5rem;
        color: #6c757d;
        font-weight: 700;
        background-color: #f5f5f5;
        border: 0 solid #ccc;
        border-bottom-width: 1px;

        &:nth-of-type(1) {
          width: 10rem;
        }

        &:nth-of-type(2) {
          width: 25rem;
        }

        &:nth-of-type(3) {
          width: 20rem;
        }

        &:nth-of-type(4) {
          width: 10rem;
        }

        &:nth-of-type(5) {
          width: 35%;
        }
      }

      td {
        text-align: left;
        padding: 0.5rem;
        border: 0 solid #ccc;
        border-bottom-width: 1px;

        &:nth-of-type(1) {
          font-weight: 500;
        }
      }
    }

    p {
      margin: 0.5rem 0;
    }

    ul {
      list-style: none;

      > li {
        list-style: circle;
        margin-left: 2rem;
        padding: 0.25rem 0;
      }
    }

    &.dark {
      th {
        background: $title-background;
        color: #fff;
      }
    }
  }

  &.comp-container {
    min-height: 5rem;

    &.dark {
      &#card1-cmp,
      &#card2-cmp,
      &#card3-cmp,
      &#card4-cmp,
      &#card5-cmp,
      &#card8-cmp,
      &#card9-cmp,
      &#card10-cmp,
      &#card14-cmp {
        padding: 1rem;
        background: #333 !important;
      }
    }
  }

  &.page-container {
    padding: 2rem;

    > .card {
      margin-bottom: 1rem;
    }
  }

  &.btn-area {
    > .card {
      display: flex;
      width: unset;
      flex: 1 1 auto;
    }

    > .button {
      margin: 0.5rem 0.5rem 0.5rem 0;
    }
  }

  &.sample-container {
    > .card {
      margin-bottom: 2rem;
    }
  }

  &.desc {
    border: none;
    border-top: 1px dotted #cccccc;
    //font-size: 14px;

    > legend {
      padding: 0.5rem;
    }

    > .desc-area {
      word-break: break-all;
      word-wrap: break-word;
      padding: 0.5rem;
      text-indent: 2rem;
    }
  }

  &.doc-row {
    margin-bottom: 1rem;

    &.space {
      max-width: 300px;
    }
  }

  &.desc-row {
    text-indent: 2rem;
    font-size: 0.95em;

    p {
      line-height: 1.8rem;
      letter-spacing: 0.03em;
    }
  }

  &.doc-col {
    display: inline-flex;
    margin-right: 1rem;
  }

  .sample-grid {
    .row {
      border: 1px solid rgba(86, 61, 124, 0.2);
      margin-top: 0.25rem;
      margin-bottom: 0.25rem;

      > div[class^='col'] {
        padding: 0.5rem;
        border: 1px solid rgba(86, 61, 124, 0.2);
        background: rgba(7, 143, 251, 0.15);
      }
    }
  }

  &.nav-menu {
    font-size: 0.9em;
    //background: #fff;
    padding: 1rem;
    color: #807e82;

    .menu-item {
      padding: 0.15rem 0.5rem;
    }
  }

  &.title-row {
    background: #f5f5f5;

    &.dark {
      background: $title-background;
    }

    h3 {
      display: inline-block;
    }
  }

  &.title-col {
    display: flex;
    align-items: center;

    .button {
      margin-left: 0.5rem;
    }
  }

  &.selected-item {
    color: #0ca0ff;
  }

  &.edit-btn {
    font-size: 1em;
    color: gray;
  }
}

.doc.dark {
  &.code {
    background: #2c2b2b;
    border: none;
    color: rgba(255, 255, 255, 0.8);
  }

  &.content-col {
    border-left: none;
    border-right: none;
  }

  &.nav-menu {
    color: #fff;
  }

  &.home-header,
  &.comp-container {
    .navbar.primary {
      background: #292929;
    }
  }
}

.banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.banner {
  //used
  display: block;
  text-align: center;
  width: 70%;
  margin: 0 auto;
  border: 0;
  padding: 6rem 0;
  color: #fff;

  > * {
    //todo: verify
    margin-top: 1.5rem;
  }

  .title {
    text-shadow: 1px 1px 2px #333, 0 0 25px #fff, 0 0 5px #0ca0ff;
    font-size: 4.5rem;
    font-weight: 500;
  }

  .content {
    font-size: 2rem;
    text-align: center;
  }

  .line {
    text-align: center;

    &.foot-line {
      position: fixed;
      bottom: 1rem;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }

    > .button {
      width: 10rem;

      + .button {
        margin-left: 1rem;
      }
    }
  }
}

.site-icon {
  color: rgb(73, 184, 71);
  animation: rotation infinite 5s linear;
}

ul {
  list-style: none;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and ('max-width': '768px') {
  .html {
    font-size: 16px;
  }
  .doc.left-nav-col,
  .doc.right-col {
    display: none;
  }

  .doc.center-col {
    padding: 7rem 1rem 0rem;
    flex: 1 1 auto;
  }

  .doc .line > .button {
    width: 8rem;
  }

  .doc .banner-container {
    //height: 20rem;
    padding: 0;
  }

  .doc .banner {
    padding: 0;
    width: 90%;

    > .title {
      font-size: 2rem;
      margin-top: 1rem;

      svg {
        font-size: 1.5rem !important;
      }
    }

    > .content {
      font-size: 1.25rem;
    }
  }

  .doc.page-container {
    padding: 0.5rem;
  }

  h3 {
    font-size: 1em;
  }

  h2 {
    font-size: 1.25em;
  }

  .doc.home-header {
    &.affix-fixed {
      line-height: 3rem;
    }

    > .navbar {
      padding: 0 !important;
    }
  }

  .doc.normal-panel {
    padding: 4rem 0;
  }

  iframe {
    width: 100% !important;
  }
}

.extra-tab {
  border-radius: 0.5rem 0.5rem 0 0;
  color: rgba(255, 255, 255, 0.6);
  background: rgb(10, 137, 219);
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.15);

  .tab-item {
    margin-right: 0 !important;

    &:nth-child(1) {
      border-top-left-radius: 0.5rem !important;
    }

    &:nth-child(3) {
      border-top-right-radius: 0.5rem !important;
    }

    &:hover {
      color: #fff !important;
    }

    &.active {
      color: #fff !important;
      background: rgba(12, 160, 255, 5);
      text-shadow: 1px 1px 2px #333;
    }
  }

  .tab-bar {
    border-bottom: 4px solid rgb(255, 0, 0) !important;
  }
}

.custom-bd {
  &.breadcrumb {
    > .item.active,
    > .item:focus,
    > .item:hover {
      color: #fff !important;
    }
  }
}

@media screen and ('min-width': '768px') {
  .doc.left-nav-col,
  .doc.right-col {
    display: block;
  }
}
